<template>
  <div class="ebp-divider">
    <p>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "ebp-divider"
};
</script>

<style lang="scss">
.ebp-divider {
  width: 100%;
  background: #ced4da;
  height: 0.5px;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    background: white;
    padding: 0 10px;
    color: #8f8f8f;
  }
}
</style>
